<div class="container">
  <h2 class="mat-h2" i18n>
    你確定要刪除<span class="name">{{ name }}</span>嗎？
  </h2>

  <form [formGroup]="form" (ngSubmit)="onSubmit()">
    <div fxLayout="column" fxLayoutGap="30px">
      <p fxFlex="1 0 0" i18n>
        為了確保資料的安全性，請輸入確認碼
        <span class="check-code">{{ checkCode }}</span>
      </p>

      <mat-form-field fxFlex="1 0 0" legacyAppearance>
        <mat-label i18n>確認碼</mat-label>
        <input matInput formControlName="checkCode" />
        <mat-error
          *ngIf="
            checkCodeInputControl.errors &&
            checkCodeInputControl.errors['comparison-string']
          "
          i18n
        >
          輸入的確認碼不符
        </mat-error>
      </mat-form-field>

      <div fxFlex="1 0 0">
        <button
          mat-raised-button
          type="submit"
          color="primary"
          [disabled]="form.invalid"
          i18n
        >
          確定刪除
        </button>
      </div>
    </div>
  </form>
</div>
