import { Injectable } from '@angular/core';
import { __Column, AccountDto } from '@ay-gosu/server-shared';
import {
  BehaviorSubject,
  combineLatest,
  firstValueFrom,
  Observable,
  ReplaySubject,
} from 'rxjs';
import {
  debounceTime,
  delay,
  first,
  shareReplay,
  switchMap,
} from 'rxjs/operators';
import { TokenService } from './token.service';

@Injectable({
  providedIn: 'root',
})
export abstract class PreloadService<T extends __Column> {
  protected _all$: ReplaySubject<T[]> = new ReplaySubject();

  public reload$ = new BehaviorSubject(1);

  public all$: Observable<T[]> = this._tokenService.account$.pipe(
    delay(333),
    switchMap(() => this._all$),
    shareReplay(1),
  );

  public constructor(protected _tokenService: TokenService) {}

  public isFirstTime = true;

  private _prevAccount: AccountDto = null;
  private _prevAll: T[] = null;

  private _loadAllDataWhenLogged = combineLatest([
    this._tokenService.account$,
    this.reload$,
  ])
    .pipe(debounceTime(100))
    .subscribe(async ([account]) => {
      if (account === null) {
        return;
      }

      this._all$ = new ReplaySubject();

      if (this._prevAccount?.companyId !== account?.companyId) {
        this._prevAll = [];
      }

      let current = await this.load();

      this._prevAll.map((old) => {
        let index = current.findIndex((_new) => this.identify(old, _new));
        if (index === -1) {
          return;
        }
        this.useOldRef(current, index, old);
      });

      this.isFirstTime = false;
      this._prevAll = current;
      this._prevAccount = account;
      this._all$.next(current);
    });

  protected abstract load(): Promise<T[]>;

  protected async afterCreate(id: number) {
    let all = await firstValueFrom(
      this.all$.pipe(first((item) => item !== null)),
    );
    let item = await this.get(id);
    all.push(item);
    this._all$.next(all);
  }

  protected async afterUpdate(id: number) {
    let all = await firstValueFrom(
      this.all$.pipe(first((item) => item !== null)),
    );
    let idx = all.findIndex((iter) => iter && iter.id === id);
    let item = await this.get(id);

    if (idx !== -1) {
      all[idx] = item;
    } else {
      all.push(item);
    }
    this._all$.next(all);
  }

  protected async afterDelete(id: number) {
    let all = await firstValueFrom(
      this.all$.pipe(first((item) => item !== null)),
    );
    let idx = all.findIndex((iter) => iter.id === id);
    if (idx !== -1) {
      all.splice(idx, 1);
    }
    this._all$.next(all);
  }

  protected abstract get(id: number): Promise<T>;

  protected identify(a: T, b: T) {
    return a.id === b.id;
  }

  protected useOldRef(list: T[], index: number, old: T) {
    let current = list[index];

    for (const key in old) {
      delete old[key];
    }

    for (const key in current) {
      old[key] = current[key];
    }

    list[index] = old;
  }
}
