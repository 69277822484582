import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatRippleModule } from '@angular/material/core';
import { MatFlatTreePicker } from './flat-tree-picker';
import { MatTreePickerOutlet } from './outlet';
import { MAT_TREE_PICKER_SCROLL_STRATEGY_FACTORY_PROVIDER } from './scroll-strategy';
import { MatTreePicker } from './tree-picker';
import { MatTreePickerNode, MatTreePickerNodeDef } from './tree-picker-node';
import { MatTreePickerNodeList } from './tree-picker-node-list';
import { MatTreePickerTrigger } from './tree-picker-trigger';

@NgModule({
    imports: [CommonModule, MatRippleModule, MatTreePicker,
        MatFlatTreePicker,
        MatTreePickerTrigger,
        MatTreePickerNode,
        MatTreePickerNodeList,
        MatTreePickerNodeDef,
        MatTreePickerOutlet],
    providers: [MAT_TREE_PICKER_SCROLL_STRATEGY_FACTORY_PROVIDER],
    exports: [
        MatTreePicker,
        MatFlatTreePicker,
        MatTreePickerTrigger,
        MatTreePickerNode,
        MatTreePickerNodeDef,
    ],
})
export class MatTreePickerModule {}
