import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatDividerModule } from '@angular/material/divider';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { MatTooltipModule } from '@angular/material/tooltip';
import { RouterModule } from '@angular/router';
import { PipeModule } from '../../pipe/pipe.module';
import { ElementRefModule } from '../element-ref.directive';
import { GosuIconModule } from '../gosu-icon';
import { GosuLogoModule } from '../gosu-logo/logo.module';
import { IconModule } from '../icon';
import { ToolbarComponent } from './toolbar.component';

@NgModule({
    imports: [
        CommonModule,
        ElementRefModule,
        FlexLayoutModule,
        GosuIconModule,
        GosuLogoModule,
        MatButtonModule,
        MatDividerModule,
        MatIconModule,
        IconModule,
        MatMenuModule,
        MatTooltipModule,
        MatCheckboxModule,
        RouterModule,
        PipeModule,
        ToolbarComponent,
    ],
    exports: [ToolbarComponent],
})
export class ToolbarModule {}
