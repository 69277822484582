import { Component, Inject, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatDrawer } from '@angular/material/sidenav';
import { Router } from '@angular/router';
import { Subject } from 'rxjs';
import {
  debounceTime,
  distinctUntilChanged,
  filter,
  map,
  shareReplay,
  startWith,
  takeUntil,
} from 'rxjs/operators';
import { version } from '../environments/version';
import { AsyncJobStatusEnum } from './components/async-job/async-job';
import { COPYRIGHT } from './provider';
import { ConnectionService } from './service/connection.service';
import { GoogleTagManagerService } from './service/google-tag-manager.service';
import { I18nService } from './service/i18n.service';
import { PageService } from './service/page.service';
import { TokenService } from './service/token.service';
import { getExpansionTrigger } from './trigger/expansion';
import { getSlideDownTrigger } from './trigger/slide';

@Component({
  selector: 'gosu-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  animations: [getSlideDownTrigger(), getExpansionTrigger()],
})
export class AppComponent implements OnInit, OnDestroy {
  public backstageVersion = version;

  private readonly _destroy$ = new Subject<void>();

  public disableToolbarRouters = [
    'login',
    'market-place/bind',
    'embedded-private-message',
  ];

  public enableToolbar$ = this._router.events.pipe(
    startWith(null),
    takeUntil(this._destroy$),
    debounceTime(100),
    map(
      () =>
        this.disableToolbarRouters.find((rule) =>
          location.pathname.includes(rule),
        ) === undefined,
    ),
    shareReplay(1),
  );

  public isConnected$ = this._connectionService.status$.pipe(
    distinctUntilChanged(),
  );

  public isLogging$ = this._tokenService.connectedToken$.pipe(
    map((job) => job.status !== AsyncJobStatusEnum.LOADING),
    distinctUntilChanged(),
  );

  @ViewChild('drawer', { static: false })
  public drawer: MatDrawer;

  public closeDrawerWhenLogout = this._tokenService.account$
    .pipe(
      filter((token) => token === null),
      takeUntil(this._destroy$),
    )
    .subscribe((token) => {
      if (!this.drawer) return;
      this.drawer.close();
    });

  public constructor(
    private readonly _router: Router,
    private readonly _connectionService: ConnectionService,
    private readonly _tokenService: TokenService,
    private readonly _googleTagManagerService: GoogleTagManagerService,
    public readonly pageService: PageService,
    @Inject(COPYRIGHT)
    public readonly copyright: string,
    private readonly _i18nService: I18nService,
  ) {}

  public ngOnInit(): void {
    this._googleTagManagerService.init();
    this._i18nService.init();
  }

  public ngOnDestroy() {
    this._destroy$.next();
    this._destroy$.complete();
  }
}
