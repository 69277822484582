<gosu-page-loading id="common"></gosu-page-loading>

<ng-container *ngIf="(isConnected$ | async) === false">
  <div class="loading-page posa" fxLayout="column">
    <div fxFlex="1 0 100px"></div>
    <div class="loading" i18n>
      <mat-spinner></mat-spinner>
      正在嘗試連線到伺服器
    </div>
    <div fxFlex="1 0 100px"></div>
  </div>
</ng-container>

<ng-container *ngIf="(isLogging$ | async) === false">
  <div class="loading-page posa" fxLayout="column">
    <div fxFlex="1 0 100px"></div>
    <div class="loading" i18n>
      <mat-spinner></mat-spinner>
      正在登入系統
    </div>
    <div fxFlex="1 0 100px"></div>
  </div>
</ng-container>

<mat-drawer-container *ngIf="(isConnected$ | async) && (isLogging$ | async)">
  <mat-drawer mode="over" #drawer>
    <nav fxLayout="column nowrap">
      <div class="logo" fxLayout="row" fxLayoutAlign="center center">
        <button class="menu" mat-icon-button (click)="drawer.toggle()">
          <gosu-icon [size]="24"></gosu-icon>
        </button>
        <gosu-logo [size]="164" theme="dark"></gosu-logo>
      </div>

      <mat-divider></mat-divider>

      <div
        class="menus"
        *ngIf="pageService.list$ | async as pages"
        fxFlex="1 0 0"
      >
        <div class="wrap" *ngFor="let item of pages" [ngSwitch]="item.type">
          <ng-template
            *ngSwitchCase="'Page'"
            [ngTemplateOutlet]="pageItem"
            [ngTemplateOutletContext]="{ $implicit: item }"
          >
          </ng-template>
          <ng-template
            *ngSwitchCase="'Redirect'"
            [ngTemplateOutlet]="redirectItem"
            [ngTemplateOutletContext]="{ $implicit: item }"
          >
          </ng-template>
          <ng-template
            *ngSwitchCase="'Tab'"
            [ngTemplateOutlet]="tabItem"
            [ngTemplateOutletContext]="{ $implicit: item }"
          >
          </ng-template>
          <ng-template
            *ngSwitchCase="'Group'"
            [ngTemplateOutlet]="groupItem"
            [ngTemplateOutletContext]="{ $implicit: item }"
          >
          </ng-template>
        </div>
      </div>

      <mat-divider></mat-divider>

      <div class="footer">
        <div class="powered-by" [innerHTML]="copyright | trustHtml"></div>
        <div class="version" i18n>版本 : {{ backstageVersion }}</div>
      </div>
    </nav>
  </mat-drawer>

  <mat-drawer-content>
    <mat-toolbar color="primary" fxLayout="row" *ngIf="enableToolbar$ | async">
      <gosu-toolbar fxFlex="1 0 0" (iconClick)="drawer.toggle()"></gosu-toolbar>
    </mat-toolbar>

    <div class="router-content">
      <router-outlet></router-outlet>
    </div>
  </mat-drawer-content>
</mat-drawer-container>

<!-- Item Template -->
<ng-template #pageItem let-page>
  <button
    mat-button
    class="item"
    routerLinkActive="active"
    [routerLink]="'/' + page.path.replace('/app', '')"
    (click)="drawer.close()"
    fxLayout="row"
  >
    <icon [icon]="page.icon"></icon>
    <p fxFlex="1 0 0">{{ page.label | translate }}</p>
  </button>
</ng-template>

<ng-template #tabItem let-tab>
  <a
    mat-button
    class="item"
    [href]="tab.path"
    target="_blank"
    (click)="drawer.close()"
    fxLayout="row"
  >
    <icon [icon]="tab.icon"></icon>
    <p fxFlex="1 0 0">{{ tab.label | translate }}</p>
  </a>
</ng-template>

<ng-template #redirectItem let-redirect>
  <a
    mat-button
    class="item"
    [href]="redirect.path"
    (click)="drawer.close()"
    fxLayout="row"
  >
    <icon [icon]="redirect.icon"></icon>
    <p fxFlex="1 0 0">{{ redirect.label | translate }}</p>
  </a>
</ng-template>

<ng-template #groupItem let-group>
  <div class="item group">
    <mat-divider></mat-divider>

    <button mat-button (click)="group.open = !group.open">
      <icon [icon]="group.icon"></icon>
      <p fxFlex="1 0 0">{{ group.label | translate }}</p>
      <icon [icon]="group.open ? 'keyboard_arrow_up' : 'keyboard_arrow_down'">
      </icon>
    </button>

    <ul [@expansion]="group.open">
      <ng-container *ngFor="let item of group.children">
        <div class="wrap" [ngSwitch]="item.type">
          <ng-template
            *ngSwitchCase="'Page'"
            [ngTemplateOutlet]="pageItem"
            [ngTemplateOutletContext]="{ $implicit: item }"
          >
          </ng-template>
          <ng-template
            *ngSwitchCase="'Redirect'"
            [ngTemplateOutlet]="redirectItem"
            [ngTemplateOutletContext]="{ $implicit: item }"
          >
          </ng-template>
          <ng-template
            *ngSwitchCase="'Tab'"
            [ngTemplateOutlet]="tabItem"
            [ngTemplateOutletContext]="{ $implicit: item }"
          >
          </ng-template>
        </div>
      </ng-container>
    </ul>

    <mat-divider></mat-divider>
  </div>
</ng-template>
