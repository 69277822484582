import { Injectable } from '@angular/core';
import { BotType } from '@ay/bot';
import union from 'lodash/union';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { BotService } from './bot.service';

export const PlatformMap = {
  LineMessagingAPI: 'Line Messaging API',
  Line: 'Line',
  LineModuleChannel: $localize`Line 應用市集`,
  Facebook: 'Facebook',
  Instagram: 'Instagram',
  MDBS: $localize`好神準`,
};

export type PlatformType = BotType | 'Line' | 'MDBS';

@Injectable({
  providedIn: 'root',
})
export class PlatformService {
  public platforms$: Observable<PlatformType[]> = of([
    'LineMessagingAPI',
    'LineModuleChannel',
    'Facebook',
    'Instagram',
    'MDBS',
  ] as PlatformType[]);

  public used$ = this._botService.all$.pipe(
    map((bot) => (bot ? union(bot.map((bot) => bot.type)) : null)),
  );

  public constructor(private readonly _botService: BotService) {}
}
