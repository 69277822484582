import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'keys',
    standalone: true,
})
export class KeysPipe implements PipeTransform {
  public transform(value: { [key: string]: any }, args?: any): any {
    return Object.keys(value);
  }
}
