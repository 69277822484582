import { AnimationEvent } from '@angular/animations';
import { Pipe, PipeTransform } from '@angular/core';
import { ActivationEnd, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';

export class UriDirectionTransition {
  public ticker: boolean;

  private _afterActivationEnd = this.router.events
    .pipe(takeUntil(this.destroy$))
    .pipe(filter((event) => event instanceof ActivationEnd))
    .subscribe((_) => (this.ticker = false));

  public constructor(
    public orders: string[],
    public router: Router,
    public destroy$: Observable<void>,
  ) {}

  public done($event: AnimationEvent) {
    if ($event.toState === 'none') return;
    this.ticker = true;
  }
}

@Pipe({
    name: 'urlDirection',
    standalone: true,
})
export class UrlDirectionPipe implements PipeTransform {
  public static from = '';

  /**
   * 當頁面轉換完成後，應該從原本的 prev 或 next 轉回 none
   * 所以需要一個 ticker 來促發更動
   */
  public transform(
    to: string,
    uriDirectionTransition: UriDirectionTransition,
  ): any {
    if (uriDirectionTransition.ticker) {
      return 'none';
    }

    const from = UrlDirectionPipe.from;

    if (from === '') {
      UrlDirectionPipe.from = to;
      return 'next';
    }

    if (from === to) {
      return 'none';
    }

    const fromIdx = uriDirectionTransition.orders.indexOf(from);
    const toIdx = uriDirectionTransition.orders.indexOf(to);
    UrlDirectionPipe.from = to;
    if (toIdx > fromIdx) return 'next';
    else return 'prev';
  }
}
