import { NgModule } from '@angular/core';
import { AccountPipe } from './account.pipe';
import { AreaTypePipe } from './area-type.pipe';
import { AttachmentIconPipe } from './attachment-icon.pipe';
import { BotPipe } from './bot.pipe';
import { ButtonTypePipe } from './button-type.pipe';
import { CouponTypePipe } from './coupon-type.pipe';
import { DateModeTypePipe } from './date-mode-type.pipe';
import { EnsureTextLengthPipe } from './ensure-text-length.pipe';
import { EscapePipe } from './escape.pipe';
import { FacebookHashTagHtmlPipe } from './facebook-hash-tag-html.pipe';
import { FileSizePipe } from './file-size.pipe';
import { FlowPipe } from './flow.pipe';
import { FromNowPipe } from './from-now.pipe';
import { ImageTypePipe } from './imageType.pipe';
import { KeysPipe } from './keys.pipe';
import { LengthPipe } from './length.pipe';
import { ModuleTypePipe } from './module.pipe';
import { MomentPipe } from './moment.pipe';
import { Nl2brPipe } from './nl2br.pipe';
import { NodePipe } from './node.pipe';
import { NumeralThousandPipe } from './numeral-thousand.pipe';
import { NumeralZhTWPipe } from './numeral-zhtw.pipe';
import { NumeralPipe } from './numeral.pipe';
import { PackageStatusColorPipe } from './package-status-color.pipe';
import { PackageStatusPipe } from './package-status.pipe';
import { PlatformPipe } from './platform.pipe';
import { ProfileStatusPipe } from './profile-status.pipe';
import { ProfileTypePipe } from './profile-type.pipe';
import { ProfilePipe } from './profile.pipe';
import { ProgressPipe } from './progress.pipe';
import { QuickReplyTypePipe } from './quick-reply-type.pipe';
import { RecordPipe } from './record.pipe';
import { RelationPipe } from './relation.pipe';
import { ReplacePipe } from './replace.pipe';
import { ReversePipe } from './reverse.pipe';
import { SplitPipe } from './split.pipe';
import { StatusPipe } from './status.pipe';
import { ThousandSeparatorPipe } from './thousand-separator.pipe';
import { TranslatePipe } from './translate.pipe';
import { TrustHtmlPipe } from './trust-html.pipe';
import { UnescapePipe } from './unescape.pipe';
import { UrlDirectionPipe } from './url-direction.pipe';
import { Url2qrcodePipe } from './url2qrcode.pipe';
import { WrapHtmlPipe } from './wrap-html.pipe';

@NgModule({
  imports: [
    AccountPipe,
    AttachmentIconPipe,
    BotPipe,
    CouponTypePipe,
    DateModeTypePipe,
    EnsureTextLengthPipe,
    EscapePipe,
    FacebookHashTagHtmlPipe,
    FlowPipe,
    FromNowPipe,
    ImageTypePipe,
    KeysPipe,
    LengthPipe,
    ModuleTypePipe,
    MomentPipe,
    Nl2brPipe,
    NodePipe,
    NumeralPipe,
    NumeralThousandPipe,
    NumeralZhTWPipe,
    PackageStatusPipe,
    PackageStatusColorPipe,
    PlatformPipe,
    ProfilePipe,
    ProfileStatusPipe,
    ProfileTypePipe,
    ProgressPipe,
    QuickReplyTypePipe,
    RecordPipe,
    RelationPipe,
    ReplacePipe,
    ReversePipe,
    SplitPipe,
    StatusPipe,
    TrustHtmlPipe,
    ThousandSeparatorPipe,
    UnescapePipe,
    Url2qrcodePipe,
    UrlDirectionPipe,
    WrapHtmlPipe,
    AreaTypePipe,
    ButtonTypePipe,
    FileSizePipe,
    TranslatePipe,
  ],
  exports: [
    AccountPipe,
    AttachmentIconPipe,
    BotPipe,
    CouponTypePipe,
    DateModeTypePipe,
    EnsureTextLengthPipe,
    EscapePipe,
    FacebookHashTagHtmlPipe,
    FlowPipe,
    FromNowPipe,
    ImageTypePipe,
    KeysPipe,
    ProgressPipe,
    LengthPipe,
    ModuleTypePipe,
    MomentPipe,
    Nl2brPipe,
    NodePipe,
    NumeralPipe,
    NumeralThousandPipe,
    NumeralZhTWPipe,
    PackageStatusPipe,
    PackageStatusColorPipe,
    PlatformPipe,
    ProfilePipe,
    ProfileStatusPipe,
    ProfileTypePipe,
    QuickReplyTypePipe,
    RecordPipe,
    RelationPipe,
    ReplacePipe,
    ReversePipe,
    SplitPipe,
    StatusPipe,
    TrustHtmlPipe,
    ThousandSeparatorPipe,
    UnescapePipe,
    Url2qrcodePipe,
    UrlDirectionPipe,
    WrapHtmlPipe,
    AreaTypePipe,
    ButtonTypePipe,
    FileSizePipe,
    TranslatePipe,
  ],
  providers: [
    AccountPipe,
    AttachmentIconPipe,
    BotPipe,
    CouponTypePipe,
    DateModeTypePipe,
    EnsureTextLengthPipe,
    EscapePipe,
    FacebookHashTagHtmlPipe,
    FlowPipe,
    FromNowPipe,
    ImageTypePipe,
    KeysPipe,
    ProgressPipe,
    LengthPipe,
    ModuleTypePipe,
    MomentPipe,
    Nl2brPipe,
    NodePipe,
    NumeralPipe,
    NumeralThousandPipe,
    NumeralZhTWPipe,
    PackageStatusPipe,
    PackageStatusColorPipe,
    PlatformPipe,
    ProfilePipe,
    ProfileStatusPipe,
    ProfileTypePipe,
    QuickReplyTypePipe,
    RecordPipe,
    RelationPipe,
    ReplacePipe,
    ReversePipe,
    SplitPipe,
    StatusPipe,
    TrustHtmlPipe,
    ThousandSeparatorPipe,
    UnescapePipe,
    Url2qrcodePipe,
    UrlDirectionPipe,
    WrapHtmlPipe,
    AreaTypePipe,
    ButtonTypePipe,
    FileSizePipe,
    TranslatePipe,
  ],
})
export class PipeModule {}
