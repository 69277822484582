import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'replace',
    pure: true,
    standalone: true,
})
export class ReplacePipe implements PipeTransform {
  public transform(input: string, key: any, word: string): string {
    try {
      return input.replace(key, word);
    } catch (err) {}
    return input;
  }
}
