import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'progress',
    standalone: true,
})
export class ProgressPipe implements PipeTransform {
  public transform(value: number, tatal: number): number {
    if (!tatal || !value) {
      return 0;
    }
    try {
      return Math.round((value / tatal) * 100);
    } catch (err) {
      console.error(err);
      return value;
    }
  }
}
