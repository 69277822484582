// 透過 @ay-nestjs/share 產生
/* eslint-disable */
import { NodeColumn } from '../node/dto/node.dto';
import { wsc } from '../wsc';
import { FlowDto } from './dto/flow.dto';

export class FlowModel {
  static fetchFlowNames(
    flowIds: number[],
  ): Promise<{ name: string; id: number }[]> {
    return wsc.execute('/ws/flow/fetchFlowNames', flowIds) as any;
  }

  static fetchNodeNames(
    nodeId: number[],
  ): Promise<{ id: number; name: string }[]> {
    return wsc.execute('/ws/flow/fetchNodeNames', nodeId) as any;
  }

  static fetchNodeById(id: number): Promise<any> {
    return wsc.execute('/ws/flow/fetchNodeById', id) as any;
  }

  static fetchNodeByPackageId(packageId: number): Promise<any> {
    return wsc.execute('/ws/flow/fetchNodeByPackageId', packageId) as any;
  }

  // #region deploy
  static deploy(flowId: number): Promise<boolean> {
    return wsc.execute('/ws/flow/deploy', flowId) as any;
  }

  static undeploy(flowId: number): Promise<boolean> {
    return wsc.execute('/ws/flow/undeploy', flowId) as any;
  }

  static create(name?: string): Promise<number> {
    return wsc.execute('/ws/flow/create', name) as any;
  }

  static rename(id: number, name?: string): Promise<boolean> {
    return wsc.execute('/ws/flow/rename', id, name) as any;
  }

  static list(): Promise<FlowDto[]> {
    return wsc.execute('/ws/flow/list') as any;
  }

  static get(id: number): Promise<FlowDto> {
    return wsc.execute('/ws/flow/get', id) as any;
  }

  static delete(id: number): Promise<boolean> {
    return wsc.execute('/ws/flow/delete', id) as any;
  }

  static update(
    flowId: number,
    botIds: number[],
    nodes: NodeColumn[],
    connections: {
      from: number;
      to: number;
      fromJunctionIdx: number;
      toJunctionIdx: number;
    }[],
  ): Promise<boolean> {
    return wsc.execute(
      '/ws/flow/update',
      flowId,
      botIds,
      nodes,
      connections,
    ) as any;
  }
}
// a4e662ab3f1f2c6962dbb356f4b8f31f2b36f9388a1ecb39af77fe445e51b312
