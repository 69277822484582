import { Component } from '@angular/core';
import { DirectusModel } from '@ay-gosu/server-shared';
import { firstValueFrom } from 'rxjs';
import { filter } from 'rxjs/operators';
import { TokenService } from '../../service/token.service';
import { MatButton } from '@angular/material/button';
import { IconComponent } from '../../components/icon/icon.component';

@Component({
    selector: 'gosu-directus',
    templateUrl: './directus.component.html',
    styleUrls: ['./directus.component.scss'],
    standalone: true,
    imports: [IconComponent, MatButton],
})
export class DirectusComponent {
  public constructor(public token: TokenService) {}

  public async login() {
    await firstValueFrom(this.token.account$.pipe(filter(Boolean)));

    const loginUrl = await DirectusModel.fetchLoginUrl();
    window.open(loginUrl, '_blank');
  }
}
