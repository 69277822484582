import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'reverse',
    pure: false,
    standalone: true,
})
export class ReversePipe implements PipeTransform {
  public transform(value) {
    return value.slice().reverse();
  }
}
