import { Pipe, PipeTransform } from '@angular/core';
import numeral from 'numeral';

@Pipe({
    name: 'numeralZhTW',
    standalone: true,
})
export class NumeralZhTWPipe implements PipeTransform {
  public transform(value: number) {
    try {
      const TE = 1000000000;
      const E = 100000000;
      const KW = 10000000;
      const TW = 10000;
      const W = 10000;
      const K = 1000;

      if ($localize.locale === 'en') {
        return numeral(value).format('0,0');
      }

      if (value >= E) {
        if (value <= TE && value % E > KW) {
          return (
            numeral(Math.floor(value / E)).format('0,0') +
            $localize`億 ` +
            Math.floor((value % E) / KW) +
            $localize`千萬`
          );
        } else {
          return numeral(Math.floor(value / E)).format('0,0') + $localize`億`;
        }
      } else if (value >= W) {
        if (value <= TW && value % W > K) {
          return (
            numeral(Math.floor(value / TW)).format('0,0') +
            $localize`萬 ` +
            Math.floor((value % TW) / K) +
            $localize`千`
          );
        } else {
          return numeral(Math.floor(value / W)).format('0,0') + $localize`萬`;
        }
      } else {
        return numeral(value).format('0,0');
      }
    } catch (err) {
      console.error(err);
      return value;
    }
  }
}
