import { Pipe, PipeTransform } from '@angular/core';

export type AttachmentType = 'cards' | 'poster' | 'image';
export const AttachmentMap = {
  cards: 'view_array',
  poster: 'dashboard',
  image: 'image',
};

@Pipe({
    name: 'attachmentIcon',
    standalone: true,
})
export class AttachmentIconPipe implements PipeTransform {
  public transform(type: AttachmentType): any {
    return AttachmentMap[type];
  }
}
