import { Pipe, PipeTransform } from '@angular/core';

export type PackageStatus =
  | 'draft'
  | 'template'
  | 'sending'
  | 'stopped'
  | 'sent'
  | 'reserved'
  | 'flow'
  | 'error'

export const PackageStatusMap = {
  draft: $localize`草稿`,
  template: $localize`範本`,
  sending: $localize`發送中`,
  stopped: $localize`已終止`,
  sent: $localize`已發送`,
  reserved: $localize`預約中`,
  flow: $localize`流程內`,
  error: $localize`發生異常`,
};

@Pipe({
    name: 'packageStatus',
    standalone: true,
})
export class PackageStatusPipe implements PipeTransform {
  public transform(status: PackageStatus): any {
    const result = PackageStatusMap[status];
    if (result === undefined) {
      console.error('Error PackageStatusPipe:', status);
      return $localize`未知`;
    }
    return result;
  }
}
