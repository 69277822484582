import { Component } from '@angular/core';
import { MatButton } from '@angular/material/button';

@Component({
    selector: 'dl-permission-denied-dialog',
    templateUrl: './permission-denied.dialog.html',
    styleUrls: ['./permission-denied.dialog.scss'],
    standalone: true,
    imports: [MatButton],
})
export class PermissionDeniedDialog {}
