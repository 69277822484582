// 透過 @ay-nestjs/share 產生
/* eslint-disable */
import { CompanyDto } from '../_module/dto/company.type';
import { wsc } from '../wsc';
import { CompanyRegisterUtmDto } from './dto/register-utm.dto';

export class CompanyModel {
  static fetch(id: number): Promise<CompanyDto> {
    return wsc.execute('/ws/company/fetch', id) as any;
  }

  static update(body: { name: string }): Promise<string> {
    return wsc.execute('/ws/company/update', body) as any;
  }

  static setProperties(property: {
    [key: string]: { value: string; reason?: string };
  }): Promise<any> {
    return wsc.execute('/ws/company/setProperties', property) as any;
  }

  static setProperty(
    /** 屬性名稱 */
    key: string,
    /** 屬性值 */
    value: any = '',
    /** 原因 */
    reason: any = '',
  ): Promise<boolean> {
    return wsc.execute('/ws/company/setProperty', key, value, reason) as any;
  }

  static getProperties(): Promise<{ [key: string]: string }> {
    return wsc.execute('/ws/company/getProperties') as any;
  }

  static getProperty(key: string): Promise<any> {
    return wsc.execute('/ws/company/getProperty', key) as any;
  }

  static register(
    /** 組織名稱 */
    name: string,
    type: 'GENERAL' | 'MODULE_CHANNEL' = 'GENERAL',
    utm: CompanyRegisterUtmDto = {},
  ): Promise<CompanyDto> {
    return wsc.execute('/ws/company/register', name, type, utm) as any;
  }

  static fetchCompaniesPasswordRules(
    accountId: number,
    companyId: number,
  ): Promise<
    {
      type: string;
      args: any;
    }[]
  > {
    return wsc.execute(
      '/ws/company/fetchCompaniesPasswordRules',
      accountId,
      companyId,
    ) as any;
  }

  static fetchPasswordDefaultRules(): Promise<
    {
      type: string;
      args: any;
    }[]
  > {
    return wsc.execute('/ws/company/fetchPasswordDefaultRules') as any;
  }
}
// a24f00ab476428f6a0155636bfe819ab35d4e44c872c43ac7862861c2c36f20b
