// 透過 @ay-nestjs/share 產生
/* eslint-disable */
import { AccountDto } from '../_module/dto/account.dto';
import { wsc } from '../wsc';
import { Observable } from 'rxjs';
import { CompanyOverviewDto } from './dto/company-overview.dto';
import { FetchLineUserInfoDto } from './dto/fetch-line-user-info.dto';
import { AccountFetchOverviewDto } from './dto/fetch-overview.dto';
import { LineLoginDto } from './dto/line-login.dto';

export class AccountModel {
  static getOverview(): Promise<AccountFetchOverviewDto[]> {
    return wsc.execute('/ws/account/getOverview') as any;
  }

  static register(
    name: string,
    email: string,
    password: string,
    phone?: string,
  ): Promise<number> {
    return wsc.execute(
      '/ws/account/register',
      name,
      email,
      password,
      phone,
    ) as any;
  }

  static login(email: string, password: string): Promise<string> {
    return wsc.execute('/ws/account/login', email, password) as any;
  }

  static loginViaToken(token: string): Promise<string> {
    return wsc.execute('/ws/account/loginViaToken', token) as any;
  }

  static loginViaEmbeddedPrivateMessageToken(token: string): Promise<{
    profileId: number;
    accountId: number;
    token: string;
    mode: 'edit' | 'preview';
  }> {
    return wsc.execute(
      '/ws/account/loginViaEmbeddedPrivateMessageToken',
      token,
    ) as any;
  }

  static refresh(): Promise<string> {
    return wsc.execute('/ws/account/refresh') as any;
  }

  static getCompanyList(): Promise<CompanyOverviewDto[]> {
    return wsc.execute('/ws/account/getCompanyList') as any;
  }

  static selectCompany(companyId: number): Promise<string> {
    return wsc.execute('/ws/account/selectCompany', companyId) as any;
  }

  static changePassword(
    oldPassword: string,
    newPassword: string,
  ): Promise<any> {
    return wsc.execute(
      '/ws/account/changePassword',
      oldPassword,
      newPassword,
    ) as any;
  }

  static isLoggedIn(): Promise<boolean> {
    return wsc.execute('/ws/account/isLoggedIn') as any;
  }

  static update(values: { name?: string }): Promise<boolean> {
    return wsc.execute('/ws/account/update', values) as any;
  }

  static info(): Promise<AccountDto> {
    return wsc.execute('/ws/account/info') as any;
  }

  static logout(): Promise<any> {
    return wsc.execute('/ws/account/logout') as any;
  }

  //#region Facebook
  static registerViaFB(accessToken: string, name: string): Promise<string> {
    return wsc.execute('/ws/account/registerViaFB', accessToken, name) as any;
  }

  static loginViaFB(accessToken: string): Promise<string> {
    return wsc.execute('/ws/account/loginViaFB', accessToken) as any;
  }

  static bindFB(accessToken: string): Promise<any> {
    return wsc.execute('/ws/account/bindFB', accessToken) as any;
  }

  static unbindFB(): Promise<any> {
    return wsc.execute('/ws/account/unbindFB') as any;
  }

  //#endregion
  //#region Google
  static registerViaGoogle(accessToken: string): Promise<string> {
    return wsc.execute('/ws/account/registerViaGoogle', accessToken) as any;
  }

  static loginViaGoogle(accessToken: string): Promise<string> {
    return wsc.execute('/ws/account/loginViaGoogle', accessToken) as any;
  }

  static bindGoogle(accessToken: string): Promise<any> {
    return wsc.execute('/ws/account/bindGoogle', accessToken) as any;
  }

  static unbindGoogle(): Promise<any> {
    return wsc.execute('/ws/account/unbindGoogle') as any;
  }

  //#endregion
  //#region LINE login
  static lineLogin(): Observable<LineLoginDto> {
    return wsc.subscribe('/ws/account/lineLogin') as any;
  }

  static registerViaLine(): Promise<any> {
    return wsc.execute('/ws/account/registerViaLine') as any;
  }

  static loginViaLine(): Promise<any> {
    return wsc.execute('/ws/account/loginViaLine') as any;
  }

  static lineUserInfo(): Promise<FetchLineUserInfoDto> {
    return wsc.execute('/ws/account/lineUserInfo') as any;
  }

  static bindLine(): Promise<any> {
    return wsc.execute('/ws/account/bindLine') as any;
  }

  static unbindLine(): Promise<any> {
    return wsc.execute('/ws/account/unbindLine') as any;
  }

  static sendResetPasswordMail(account: string): Promise<boolean> {
    return wsc.execute('/ws/account/sendResetPasswordMail', account) as any;
  }

  static verifyResetPasswordToken(token: string): Promise<boolean> {
    return wsc.execute('/ws/account/verifyResetPasswordToken', token) as any;
  }

  static changePasswordByToken(token: string, password: string): Promise<true> {
    return wsc.execute(
      '/ws/account/changePasswordByToken',
      token,
      password,
    ) as any;
  }

  static sendVerifyMail(): Promise<any> {
    return wsc.execute('/ws/account/sendVerifyMail') as any;
  }

  static sendValidateCode(
    countryCode: string,
    phone: string,
  ): Promise<boolean> {
    return wsc.execute(
      '/ws/account/sendValidateCode',
      countryCode,
      phone,
    ) as any;
  }

  static registerPhone(
    countryCode: string,
    phone: string,
    validateCode?: string,
  ): Promise<string> {
    return wsc.execute(
      '/ws/account/registerPhone',
      countryCode,
      phone,
      validateCode,
    ) as any;
  }

  static checkSmsValidate(): Promise<boolean> {
    return wsc.execute('/ws/account/checkSmsValidate') as any;
  }
}
// 9917f7016fbe753aa8aa5fe52e66e4a4dc2ef61a2e27d895ba6435317ec73cbe
