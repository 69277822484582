import { Pipe, PipeTransform } from '@angular/core';
import { PackageStatus } from './package-status.pipe';

export const PackageStatusColorMap = {
  draft: '#FFF',
  sending: '#DDD',
  sent: '#FFF',
  reserved: '#FFF',
  flow: '#CCC',
  error: '#f55564',
};

@Pipe({
    name: 'packageStatusColor',
    standalone: true,
})
export class PackageStatusColorPipe implements PipeTransform {
  public transform(status: PackageStatus): any {
    const result = PackageStatusColorMap[status];
    if (result === undefined) {
      console.error('Error PackageStatusColorMap:', status);
      return '#FFF';
    }
    return result;
  }
}
