// 透過 @ay-nestjs/share 產生
/* eslint-disable */
import { BotDto } from '../_module/dto/bot.type';
import { PropertyValue } from '../_module/dto/property-value.type';
import { wsc } from '../wsc';
import { BotAuthorizeFacebookPageDto } from './dto/authorize-facebook-page.dto';
import { BotAuthorizeInstagramDto } from './dto/authorize-instagram.dto';
import { BotBypassDto } from './dto/bypass.dto';
import { BotGetFacebookPageAuthorizeStatusDto } from './dto/get-facebook-page-authorize-status.dto';
import { BotGetInstagramAuthorizeStatusDto } from './dto/get-instagram-authorize-status.dto';

export class BotModel {
  static createLineMessagingApiBot(
    channelId: string,
    channelSecret: string,
    channelAccessToken: string,
    webhookUrl: string,
    isApproved: any = false,
  ): Promise<BotDto> {
    return wsc.execute(
      '/ws/bot/createLineMessagingApiBot',
      channelId,
      channelSecret,
      channelAccessToken,
      webhookUrl,
      isApproved,
    ) as any;
  }

  static getById(id: number): Promise<BotDto> {
    return wsc.execute('/ws/bot/getById', id) as any;
  }

  static update(
    id: number,
    options: {
      name?: string;
      secret?: string;
      token?: string;
      disabled?: boolean;
      isApproved?: boolean;
    },
  ): Promise<boolean> {
    return wsc.execute('/ws/bot/update', id, options) as any;
  }

  static getProperties(id: number): Promise<{ [key: string]: PropertyValue }> {
    return wsc.execute('/ws/bot/getProperties', id) as any;
  }

  static getProperty(id: number, key: string): Promise<PropertyValue> {
    return wsc.execute('/ws/bot/getProperty', id, key) as any;
  }

  static setProperty(
    id: number,
    key: string,
    value: PropertyValue = '',
    reason: any = '',
  ): Promise<boolean> {
    return wsc.execute('/ws/bot/setProperty', id, key, value, reason) as any;
  }

  static removeProperty(id: number, key: string): Promise<boolean> {
    return wsc.execute('/ws/bot/removeProperty', id, key) as any;
  }

  static getFacebookBotAuthorizeStatus(
    pageIds: string | string[],
  ): Promise<BotGetFacebookPageAuthorizeStatusDto> {
    return wsc.execute('/ws/bot/getFacebookBotAuthorizeStatus', pageIds) as any;
  }

  static exchangeFacebookLongToken(shortToken: string): Promise<string> {
    return wsc.execute('/ws/bot/exchangeFacebookLongToken', shortToken) as any;
  }

  static reauthorizeFacebookBot(
    page: BotAuthorizeFacebookPageDto,
  ): Promise<boolean> {
    return wsc.execute('/ws/bot/reauthorizeFacebookBot', page) as any;
  }

  static authorizeFacebookBot(
    page: BotAuthorizeFacebookPageDto,
  ): Promise<BotDto> {
    return wsc.execute('/ws/bot/authorizeFacebookBot', page) as any;
  }

  static getFacebookTokenStatus(botId: number): Promise<any> {
    return wsc.execute('/ws/bot/getFacebookTokenStatus', botId) as any;
  }

  static exchangeInstagramLongToken(shortToken: string): Promise<string> {
    return wsc.execute('/ws/bot/exchangeInstagramLongToken', shortToken) as any;
  }

  static authorizeInstagramBot(
    page: BotAuthorizeInstagramDto,
  ): Promise<BotDto> {
    return wsc.execute('/ws/bot/authorizeInstagramBot', page) as any;
  }

  static getInstagramBotAuthorizeStatus(
    pageIds: string[],
  ): Promise<BotGetInstagramAuthorizeStatusDto> {
    return wsc.execute(
      '/ws/bot/getInstagramBotAuthorizeStatus',
      pageIds,
    ) as any;
  }

  static reauthorizeInstagramBot(
    page: BotAuthorizeInstagramDto,
  ): Promise<boolean> {
    return wsc.execute('/ws/bot/reauthorizeInstagramBot', page) as any;
  }

  static getInstagramTokenStatus(botId: number): Promise<any> {
    return wsc.execute('/ws/bot/getInstagramTokenStatus', botId) as any;
  }

  static list(): Promise<any[]> {
    return wsc.execute('/ws/bot/list') as any;
  }

  static syncAllGroupMemberByBotId(botId: number): Promise<any> {
    return wsc.execute('/ws/bot/syncAllGroupMemberByBotId', botId) as any;
  }

  static syncBotInfoFromLine(botId: number): Promise<any> {
    return wsc.execute('/ws/bot/syncBotInfoFromLine', botId) as any;
  }

  static fetchQuota(botId: number): Promise<any> {
    return wsc.execute('/ws/bot/fetchQuota', botId) as any;
  }

  static ensureLiff(botId: number): Promise<any> {
    return wsc.execute('/ws/bot/ensureLiff', botId) as any;
  }

  static ensureLineMessagingApi(
    channelId: string,
    channelSecret: string,
    channelAccessToken: string,
    webhookUrl: string,
    botId: number,
  ): Promise<any> {
    return wsc.execute(
      '/ws/bot/ensureLineMessagingApi',
      channelId,
      channelSecret,
      channelAccessToken,
      webhookUrl,
      botId,
    ) as any;
  }

  static updateWebhookUrl(botId: number, webhookUrl: string): Promise<any> {
    return wsc.execute('/ws/bot/updateWebhookUrl', botId, webhookUrl) as any;
  }

  static ensureLineLogin(
    channelId: string,
    channelSecret: string,
    callbackUrl: string,
  ): Promise<true> {
    return wsc.execute(
      '/ws/bot/ensureLineLogin',
      channelId,
      channelSecret,
      callbackUrl,
    ) as any;
  }

  static fetchBotQuantityLimit(): Promise<any> {
    return wsc.execute('/ws/bot/fetchBotQuantityLimit') as any;
  }

  static fetchBypassByBotId(botId: number): Promise<BotBypassDto> {
    return wsc.execute('/ws/bot/fetchBypassByBotId', botId) as any;
  }

  static saveBypass(botId: number, bypass: BotBypassDto): Promise<any> {
    return wsc.execute('/ws/bot/saveBypass', botId, bypass) as any;
  }

  static fetchBotFollowers(botId: number): Promise<number> {
    return wsc.execute('/ws/bot/fetchBotFollowers', botId) as any;
  }
}
// 12bbcb053c3039f19460bfc9e4f03e938c652d1344680723f965721ee31aca33
