import { AsyncPipe, NgFor, NgIf } from '@angular/common';
import { Component, Inject, OnDestroy, Optional } from '@angular/core';
import { FlexModule } from '@angular/flex-layout/flex';
import { MatButton, MatIconButton } from '@angular/material/button';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatLabel } from '@angular/material/form-field';
import { MatIcon } from '@angular/material/icon';
import { MatActionList, MatList } from '@angular/material/list';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { AccountModel, CompanyDto } from '@ay-gosu/server-shared';
import { Subject, of } from 'rxjs';
import {
  catchError,
  filter,
  mergeMap,
  shareReplay,
  takeUntil,
} from 'rxjs/operators';
import { LogoComponent } from '../../../components/gosu-logo/logo.component';
import { BasicDialog } from '../../../dialog/basic';
import { FromNowPipe } from '../../../pipe/from-now.pipe';
import { TokenService } from '../../../service/token.service';
import { LoginService } from '../login.service';

@Component({
  selector: 'gosu-select-company',
  templateUrl: './select-company.component.html',
  styleUrls: ['./select-company.component.scss'],
  // eslint-disable-next-line @angular-eslint/no-host-metadata-property
  host: {
    '[class.dialog]': 'dialogData',
  },
  standalone: true,
  imports: [
    NgIf,
    LogoComponent,
    FlexModule,
    MatIconButton,
    MatIcon,
    MatLabel,
    MatList,
    NgFor,
    MatButton,
    AsyncPipe,
    FromNowPipe,
    MatActionList,
  ],
})
export class SelectCompanyComponent implements OnDestroy {
  private readonly _destroy$ = new Subject<void>();

  private _redirectToLoginWhenNotLoggedIn = this.tokenService.token$
    .pipe(
      filter((token) => !token),
      takeUntil(this._destroy$),
    )
    .subscribe((token) => this._router.navigate(['login', 'form']));

  public companies$ = this.tokenService.accountWithoutCompany$.pipe(
    mergeMap((account) => (account ? AccountModel.getCompanyList() : [])),
    catchError(() => of([])),
    shareReplay(1),
    takeUntil(this._destroy$),
  );

  private _isSelectCompanyPage$ = this._activatedRoute.url.pipe(
    filter((urls) => !!urls.find((url) => url.path === 'select-company')),
  );

  private _redirectToLandingPageWhenSelected = this._isSelectCompanyPage$
    .pipe(
      mergeMap((_) => this.tokenService.account$),
      filter((account) => !!account),
      takeUntil(this._destroy$),
    )
    .subscribe((token) => this._router.navigateByUrl('/'));

  private _redirectToCreateCompanyWhenNotCompany = this._isSelectCompanyPage$
    .pipe(
      mergeMap((_) => this.companies$),
      filter((companies) => companies.length === 0),
      takeUntil(this._destroy$),
    )
    .subscribe((_) => this._router.navigate(['login', 'create-company']));

  private _autoSelectCompanyWhenOnlyOneCompany = this._isSelectCompanyPage$
    .pipe(
      mergeMap((_) => this.companies$),
      filter((companies) => companies.length === 1),
      takeUntil(this._destroy$),
    )
    .subscribe(async (companies) => {
      try {
        await this.tokenService.selectCompany(companies[0].id);
        this._router.navigateByUrl('/');
      } catch (error) {
        console.log(error);
        if (error.code === 'NOT_PASS_PASSWORD_RULES') {
          setTimeout(() => {
            this._basicDialog.resetPassword(companies[0].id);
          }, 1000);
        }
        return;
      }
    });

  public ngOnDestroy() {
    this._destroy$.next();
    this._destroy$.complete();
  }

  public constructor(
    public readonly tokenService: TokenService,
    private readonly _loginService: LoginService,
    private readonly _router: Router,
    private readonly _activatedRoute: ActivatedRoute,
    private readonly _matSnackBar: MatSnackBar,
    @Optional()
    @Inject(MAT_DIALOG_DATA)
    public readonly dialogData: { isDialog: boolean },
    @Optional()
    private readonly _matDialogRef: MatDialogRef<any>,
    private readonly _basicDialog: BasicDialog,
  ) {}

  public async selectCompany(company: CompanyDto): Promise<void> {
    this._loginService.isLoading$.next(true);
    try {
      await this.tokenService.selectCompany(company.id);
      if (this._matDialogRef) {
        this._matDialogRef.close();
      }
      this._router.navigate(['']);
      this._matSnackBar.open($localize`成功選擇組織 ` + company.name);
    } catch (error) {
      console.log(error);
      if (error.code === 'NOT_PASS_PASSWORD_RULES') {
        if (this._matDialogRef) {
          this._matDialogRef.close();
        }
        setTimeout(() => {
          this._basicDialog.resetPassword(company.id);
        }, 1000);
      }
    }
    this._loginService.isLoading$.next(false);
  }

  public createCompany() {
    if (this._matDialogRef) {
      this._matDialogRef.close();
    }
    this._router.navigateByUrl('/login/create-company');
  }
}
