import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'thousandSeparator',
    pure: true,
    standalone: true,
})
export class ThousandSeparatorPipe implements PipeTransform {
  public transform(value: number, ...args: any[]): string {
    return value.toLocaleString('en-US');
  }
}
