import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'profileStatus',
    pure: true,
    standalone: true,
})
export class ProfileStatusPipe implements PipeTransform {
  public transform(input: string, showFollow = false): string {
    switch (input) {
      case 'Follow':
      case 'Join':
      default:
        return showFollow ? $localize`正常` : '';

      case 'Unfollow':
        return $localize`封鎖`;

      case 'Leave':
        return $localize`離開`;
    }
  }
}
