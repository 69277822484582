import { Component } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatButton } from '@angular/material/button';
import { MatDialogRef } from '@angular/material/dialog';
import { MatFormField, MatLabel } from '@angular/material/form-field';
import { MatInput } from '@angular/material/input';
import moment from 'moment';
import { DatetimePickerComponent } from '../../../components/datetime-picker/datetime-picker.component';
import { LegacyAppearanceDirective } from '../../../material/legacy/mat-form-field/legacy-appearance.directive';

@Component({
  selector: 'app-reservation-datetime-picker',
  templateUrl: './reservation-datetime-picker.component.html',
  styleUrls: ['./reservation-datetime-picker.component.scss'],
  standalone: true,
  imports: [
    MatFormField,
    MatLabel,
    MatInput,
    FormsModule,
    MatButton,
    LegacyAppearanceDirective,
    DatetimePickerComponent,
  ],
})
export class ReservationDatetimePickerComponent {
  public value = moment().add(1, 'hour').format('YYYY-MM-DDTHH:mm');

  public min = moment().add(1, 'hour');

  public max = moment().add(1, 'year');

  public constructor(
    private _matDialogRef: MatDialogRef<ReservationDatetimePickerComponent>,
  ) {}

  public submit() {
    this._matDialogRef.close(this.value);
  }
}
