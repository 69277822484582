import { Injectable } from '@angular/core';
import { CompanyDto, CompanyModel } from '@ay-gosu/server-shared';
import { safeJsonParse } from '@ay/util';
import { BehaviorSubject, combineLatest } from 'rxjs';
import { mergeMap, shareReplay } from 'rxjs/operators';
import { TokenService } from './token.service';

export interface UTM {
  utm_source?: string;
  utm_medium?: string;
  utm_campaign?: string;
  utm_term?: string;
  utm_content?: string;
}

@Injectable({
  providedIn: 'root',
})
export class CompanyService {
  public updateCompany$ = new BehaviorSubject(null);

  public company$ = combineLatest([
    this._tokenService.account$,
    this.updateCompany$,
  ])
    .pipe(
      mergeMap(async ([account]) =>
        account ? this._fetch(account.companyId) : null,
      ),
    )
    .pipe(shareReplay(1));

  public constructor(private readonly _tokenService: TokenService) {}

  public async createCompany(name: string): Promise<CompanyDto> {
    const utm = this.getUTM();
    return CompanyModel.register(name, 'GENERAL', utm);
  }

  private getUTM(): UTM {
    return safeJsonParse(localStorage.getItem('UTM'), {});
  }

  private async _fetch(companyId): Promise<CompanyDto> {
    if (!companyId) {
      throw $localize`加載組織詳細資料時發生錯誤，無效的組織編號 ${companyId}`;
    }

    return CompanyModel.fetch(companyId);
  }

  public async fetchCompaniesPasswordRules(
    accountId: number,
    companyId: number,
  ): Promise<{ type: string; args: any }[]> {
    return CompanyModel.fetchCompaniesPasswordRules(accountId, companyId);
  }

  public fetchPasswordDefaultRules(): Promise<{ type: string; args: any }[]> {
    return CompanyModel.fetchPasswordDefaultRules();
  }
}
