import { NgIf } from '@angular/common';
import { Component, Inject, Input, Optional } from '@angular/core';
import { FlexModule } from '@angular/flex-layout/flex';
import {
  FormControl,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { MatButton } from '@angular/material/button';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatError, MatFormField, MatLabel } from '@angular/material/form-field';
import { MatInput } from '@angular/material/input';
import { createRandomString } from '@ay/util';
import { comparisonString } from '../../../../util/validators/comparison-string';
import { LegacyAppearanceDirective } from '../../../material/legacy/mat-form-field/legacy-appearance.directive';

const CHECK_CODE_LENGTH = 4;
const CHECK_CODE_CHARS = 'ABCDEFGHJKMNPQRSTUVWXYZ23456789';

@Component({
  selector: 'dl-delete-confirm-dialog',
  templateUrl: './delete-confirm.dialog.html',
  styleUrls: ['./delete-confirm.dialog.scss'],
  standalone: true,
  imports: [
    FormsModule,
    ReactiveFormsModule,
    FlexModule,
    MatFormField,
    MatLabel,
    MatInput,
    NgIf,
    MatError,
    MatButton,
    LegacyAppearanceDirective,
  ],
})
export class DeleteConfirmDialog {
  @Input()
  public name = '';

  public checkCode = this.createCheckCode();

  public checkCodeInputControl = new FormControl(
    '',
    Validators.compose([
      (control: FormControl) =>
        comparisonString(control, this.checkCode, { ignoreCase: true }),
    ]),
  );

  public form = new FormGroup({ checkCode: this.checkCodeInputControl });

  public constructor(
    public dialogRef: MatDialogRef<DeleteConfirmDialog>,
    @Inject(MAT_DIALOG_DATA)
    @Optional()
    public data,
  ) {
    this.name = data?.name ?? '';
  }

  public createCheckCode() {
    return createRandomString(CHECK_CODE_CHARS, CHECK_CODE_LENGTH);
  }

  public onSubmit() {
    this.dialogRef.close(true);
  }
}
