import { Pipe, PipeTransform } from '@angular/core';
import { Map } from '@ay/util';

const ReactionTypeMap: Map<string> = {
  love: $localize`大心`,
  like: $localize`讚`,
  haha: $localize`哈哈`,
  wow: $localize`哇`,
  sad: $localize`嗚`,
  angry: $localize`怒`,
};

@Pipe({
    name: 'record',
    standalone: true,
})
export class RecordPipe implements PipeTransform {
  public transform(record: any) {
    switch (record.type) {
      case 'text':
        return record.content;

      case 'image':
        return record.alt || $localize`傳送了一張圖片`;

      case 'poster':
        return record.alt || $localize`傳送了一張海報`;

      case 'card':
      case 'cards':
        return record.alt || $localize`傳送了一組卡片`;

      case 'flex':
        return record['altText'] || $localize`傳送了一張Flex訊息`;

      case 'coupon':
        return $localize`傳送了一張優惠券`;

      case 'unfollow':
        return $localize`封鎖機器人`;

      case 'follow':
        return $localize`與機器人成為好友`;

      case 'join':
        return $localize`機器人加入群組`;

      case 'leave':
        return $localize`機器人離開群組`;

      case 'memberLeft':
        return $localize`有成員離開群組`;

      case 'memberJoined':
        return $localize`新成員加入群組`;

      case 'location':
        return $localize`傳送了地理位置`;

      case 'file':
        return $localize`傳送了檔案`;

      case 'add-reaction':
        return $localize`表達了${ReactionTypeMap[record.content] || '心情'}`;

      case 'add-comment':
        return $localize`新增了留言`;

      case 'remove-comment':
        return $localize`刪除了留言`;

      case 'postback':
        return $localize`點擊了按鈕`;

      case 'coupon':
        return $localize`傳送了一張優惠券`;

      case 'mme':
        return $localize`點擊了 m.me 連結`;

      case 'add-like-page':
        return $localize`按了粉絲專頁讚`;

      case 'remove-like-page':
        return $localize`收回了粉絲專頁的讚`;

      case 'edit-reaction':
        let reaction = ReactionTypeMap[record.content];
        return reaction
          ? $localize`變更了表達的心情為${reaction}`
          : $localize`變更了表達的心情`;

      case 'remove-reaction':
        return $localize`收回了${ReactionTypeMap[record.content] || '心情'}`;

      case 'add-post-customer':
      case 'add-post-editors':
        return $localize`發表了一則貼文`;

      case 'remove-post-customer':
      case 'remove-post-editors':
        return $localize`刪除了一則貼文`;

      case 'edited-post-customer':
      case 'edited-post-editors':
        return $localize`編輯了一則貼文`;

      default:
        if (record.content && record.content['type']) {
          return this.transform(record.content as any);
        }
        console.error($localize`RecordPipe 尚未支援的訊息格式`, { record });
    }
  }
}
