import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import {
  faFacebook,
  faFacebookF,
  faFacebookMessenger,
  faFacebookSquare,
  faGoogle,
  faInstagram,
  faLine,
} from '@fortawesome/free-brands-svg-icons';
import {
  faArrowsAlt,
  faBars,
  faChartLine,
  faCircle,
  faComments,
  faCopy,
  faExchangeAlt,
  faFilter,
  faFont,
  faGifts,
  faPhotoVideo,
  faQuestion,
  faSignature,
  faSquare,
  faStar,
  faTicketAlt,
  faUser,
  faUsers,
} from '@fortawesome/free-solid-svg-icons';
import { MatIcon } from '@angular/material/icon';
import { InlineSVGDirective } from '../../ng-inline-svg/inline-svg.directive';
import { FaIconComponent } from '@fortawesome/angular-fontawesome';
import { NgIf } from '@angular/common';

@Component({
    selector: 'icon',
    templateUrl: './icon.component.html',
    styleUrls: ['./icon.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [
        NgIf,
        FaIconComponent,
        InlineSVGDirective,
        MatIcon,
    ],
})
export class IconComponent {
  public faIcons = {
    'fa-exchange-alt': faExchangeAlt,
    'fa-gifts': faGifts,
    'fa-ticket-alt': faTicketAlt,
    'fa-facebook-messenger': faFacebookMessenger,
    'fa-line': faLine,
    'fa-facebook-square': faFacebookSquare,
    'fa-facebook-f': faFacebookF,
    'fa-facebook': faFacebook,
    'fa-instagram': faInstagram,
    'fa-google': faGoogle,
    'fa-filter': faFilter,
    'fa-star': faStar,
    'fa-question': faQuestion,
    'fa-user': faUser,
    'fa-users': faUsers,
    'fa-signature': faSignature,
    'fa-copy': faCopy,
    'fa-bars': faBars,
    'fa-arrows': faArrowsAlt,
    'fa-font': faFont,
    'fa-picture-o': faPhotoVideo,
    'fa-square-o': faSquare,
    'fa-circle-thin': faCircle,
    'fa-line-chart': faChartLine,
    'fa-comments': faComments,
  };

  @Input()
  public icon: string;

  public get isSvgFile() {
    return this.icon.startsWith('/') || this.icon.startsWith('.');
  }

  public get isFaIcon() {
    return this.icon.startsWith('fa-');
  }
}
