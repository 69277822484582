import { Component, HostBinding, Input } from '@angular/core';
import { NgClass } from '@angular/common';

@Component({
    selector: 'gosu-icon',
    templateUrl: './icon.component.html',
    styleUrls: ['./icon.component.scss'],
    standalone: true,
    imports: [NgClass],
})
export class IconComponent {
  @Input()
  public theme: 'light' | 'dark' = 'dark';

  @Input()
  @HostBinding('style.width.px')
  @HostBinding('style.height.px')
  public size = 32;
}
