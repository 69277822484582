import { Pipe, PipeTransform } from '@angular/core';
import numeral from 'numeral';

@Pipe({
  name: 'numeralThousand',
  standalone: true,
})
export class NumeralThousandPipe implements PipeTransform {
  public transform(value: any, format: string = '0,0'): any {
    try {
      return numeral(value).format(format);
    } catch (err) {
      console.error(err);
      return value;
    }
  }
}
