<mat-label i18n>預約的時間</mat-label>
<gosu-datetime-picker
  [(value)]="value"
  [min]="min"
  [max]="max"
></gosu-datetime-picker>

<div class="tar">
  <button mat-raised-button (click)="submit()" color="primary" i18n>
    預約
  </button>
</div>
